//lib
import Axios from 'axios';

const _url = 'https://phygicool-api.azurewebsites.net/';

export default {
  getNfts: (accountId) =>
    Axios.get(_url + '/account/' + accountId + '/nfts')
      .then(resp => resp.data)
};
