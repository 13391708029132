//ui lib
import _ from 'lodash';
import Spinner from 'react-bootstrap/Spinner';
//styles
import styles from './selectNft.module.css';

const SelectNftView = (props) => {
  const {
    nfts,
    onValid,
    isLoading
  } = props;

  const _tile = (index, items) => {
    return (
      <div className={styles.rowContainer} key={index}>
        <div className={styles.row}>
          {
            items.map((item, i) =>
              <div className={styles.tile} key={i} onClick={() => onValid(item)}>
                <div className={styles.nftContainer}>
                  <div>
                    <img src={item.image} width={'100%'} alt={'Loading...'} />
                  </div>
                  <div className={styles.nftName}>{item.name}</div>
                </div>
              </div>)
          }
        </div>
      </div>
    );
  };

  return (
    <div >
      <p className={styles.pageTitle}>Please Select Your NFT</p>

      {
        !isLoading &&
          <>
            <div className='d-block d-sm-none'>
              {
                _.chunk(nfts, 1).map((chunk, i) =>
                  _tile(i, chunk))
              }
            </div>
            <div className='d-none d-sm-block d-md-none'>
              {
                _.chunk(nfts, 2).map((chunk, i) =>
                  _tile(i, chunk))
              }
            </div>
            <div className='d-none d-md-block d-lg-none'>
              {
                _.chunk(nfts, 3).map((chunk, i) =>
                  _tile(i, chunk))
              }
            </div>
            <div className='d-none d-lg-block d-xl-none'>
              {
                _.chunk(nfts, 4).map((chunk, i) =>
                  _tile(i, chunk))
              }
            </div>
            <div className='d-none d-xl-block'>
              {
                _.chunk(nfts, 5).map((chunk, i) =>
                  _tile(i, chunk))
              }
            </div>
          </>
      }

      {
        isLoading &&
        <div className={styles.spinnerContainer}>
          <Spinner color={'#307a85 '} className={styles.spinner} />
          <p className={styles.spinnerText}>Retrieving your NFTs</p>
        </div>
      }
    </div>
  )
};

export default SelectNftView;
