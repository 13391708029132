import {useEffect} from 'react';
//components
import View from './processingPaymentView';

const ProcessingPaymentContainer = (props) => {
  const {
    isCurrentIndex,
    onValid
  } = props;

  useEffect(() => {
    if (!isCurrentIndex)
      return;

    setTimeout(onValid, 3000);
  }, [isCurrentIndex]);

  if (!isCurrentIndex)
    return null;

  return (
    <View/>
  );
};

export default ProcessingPaymentContainer;
