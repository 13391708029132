//components
import View from './selectPrintingOptionsView';

const SelectPrintingOptionsContainer = (props) => {
  const {
    isCurrentIndex,
    onValid,
    selectedNft
  } = props;

  if (!isCurrentIndex)
    return null;

  return (
    <View
      onValid={onValid}
      selectedNft={selectedNft} />
  );
};

export default SelectPrintingOptionsContainer;
