//components
import View from './confirmPrintView';

const ConfirmPrintContainer = (props) => {
  const {
    isCurrentIndex,
  } = props;

  if (!isCurrentIndex)
    return null;

  return (
    <View
      {...props} />
  );
};

export default ConfirmPrintContainer;
