//router
import Router from './navigation/router';
//lib
import 'bootstrap/dist/css/bootstrap.min.css';
//components
import Header from './components/header';
//styles
import styles from './app.module.css';
//Web3
import {EthereumClient, modalConnectors, walletConnectProvider,} from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { arbitrum, mainnet, polygon } from 'wagmi/chains';

const chains = [arbitrum, mainnet, polygon];

// Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: '3196be1b5abde6cbe2d5c64da231e851' }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: 'web3Modal', chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

function App() {
  return (
    <div className={styles.background}>
      <WagmiConfig client={wagmiClient}>
        <Header />
        <Router />
      </WagmiConfig>

      <Web3Modal
        projectId='3196be1b5abde6cbe2d5c64da231e851'
        ethereumClient={ethereumClient}
      />
    </div>
  );
}

export default App;
