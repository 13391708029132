import {useEffect, useState} from 'react';
//components
import View from './selectNftView';
//utils
import PhygicoolApi from '../../utils/dataTransfer';

const SelectNftContainer = (props) => {
  const {
    isCurrentIndex,
    onValid,
    walletAddress
  } = props;
  const [state, setState] = useState({
    nfts: [],
    isLoading: true
  });

  useEffect(() => {
    if (!isCurrentIndex || state.nfts.length || !walletAddress)
      return;

    _getNfts();
  }, [isCurrentIndex, walletAddress]);

  const _getNfts = async () => {
    let nfts: [];
    try {
      nfts = await PhygicoolApi.getNfts(walletAddress);
    } catch (e) {
      console.log(e.error);
    }

    setState(prevState => ({
      ...prevState,
      nfts: nfts.items,
      isLoading: false
    }));
  };

  if (!isCurrentIndex)
    return null;

  return (
    <View
      onValid={onValid}
      {...state} />
  );
};

export default SelectNftContainer;
