//components
import View from './orderCompleteView';

const OrderCompleteContainer = (props) => {
  const {
    isCurrentIndex,
    onValid
  } = props;

  if (!isCurrentIndex)
    return null;

  return (
    <View
      onValid={onValid}/>
  );
};

export default OrderCompleteContainer;
