import {useEffect} from 'react';
//components
import View from './connectWalletView';
//web3
import { useWeb3Modal } from '@web3modal/react';
import { useAccount } from 'wagmi';

const ConnectWalletContainer = (props) => {
  const {
    isCurrentIndex,
    onValid
  } = props;
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();

  useEffect(() => {
    if (!isCurrentIndex || !isConnected)
      return;

    onValid(address);
  }, [isConnected, isCurrentIndex])

  const openWalletConnect = async () => {
    await open({route: 'ConnectWallet'});
  };

  if (!isCurrentIndex)
    return null;

  return (
    <View
      openWalletConnect={openWalletConnect}/>
  );
};

export default ConnectWalletContainer;
