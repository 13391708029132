//components
import Button from '../button';
//styles
import styles from './connectWallet.module.css';

const ConnectWalletView = (props) => {
  const {openWalletConnect} = props;

  return (
    <div>
      <p className={styles.pageTitle}>Please Connect Your Wallet</p>
      <div className={styles.spinnerContainer}>
        <p className={styles.text}>To begin your order please connect to your Cryto Wallet using the button below.</p>
        <Button onClick={openWalletConnect}>
          Connect Wallet
        </Button>
      </div>
    </div>
  )
};

export default ConnectWalletView;
