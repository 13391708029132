import {useState, useEffect} from 'react';
//ui lib
import Container from 'react-bootstrap/Container';
//components
import ConnectWallet from '../../../components/connectWallet';
import SelectNft from '../../../components/selectNft';
import SelectPrintingOptions from '../../../components/selectPrintingOptions';
import EnterDeliveryAddress from '../../../components/enterDeliveryAddress';
import ConfirmPrint from '../../../components/confirmPrint';
import OrderComplete from '../../../components/orderComplete'
import ProcessingPayment from '../../../components/processingPayment';
//styles
import styles from './printNft.module.css';

const PrintNftView = (props) => {
  const [_currentIndex, _setCurrentIndex] = useState(0);
  const {
    processViewData,
    selectedNft,
    printOptions,
    deliveryAddress,
    clearViewData,
    walletAddress,
    isConnected
  } = props;

  const next = (data) => {
    processViewData(data, _currentIndex);

    _setCurrentIndex(_currentIndex + 1);
  }

  const clear = () => {
    clearViewData();

    _setCurrentIndex(0);
  };

  useEffect(() => {
    if (isConnected || _currentIndex === 0)
      return;

    clear();
  }, [isConnected]);

  return (
    <Container>
      <div className={styles.content}>
        <ConnectWallet isCurrentIndex={_currentIndex === 0} onValid={next} />
        <SelectNft isCurrentIndex={_currentIndex === 1} onValid={next} walletAddress={walletAddress} />
        <SelectPrintingOptions isCurrentIndex={_currentIndex === 2} onValid={next} selectedNft={selectedNft} />
        <EnterDeliveryAddress isCurrentIndex={_currentIndex === 3} onValid={next} />
        <ConfirmPrint isCurrentIndex={_currentIndex === 4} onValid={next} selectedNft={selectedNft} printOptions={printOptions} deliveryAddress={deliveryAddress} />
        <ProcessingPayment isCurrentIndex={_currentIndex === 5} onValid={next} />
        <OrderComplete isCurrentIndex={_currentIndex === 6} onValid={clear} />
      </div>
    </Container>
  );
};

export default PrintNftView;
