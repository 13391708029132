import {useState} from 'react';
//ui lib
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//lib
import _ from 'lodash';
//styles
import styles from './selectPrintingOptions.module.css';
//components
import Button from '../button';
import Radio from '../radio';

const SelectPrintingOptionsView = (props) => {
  const [_selectedSize, _setSelectedSize] = useState('20');
  const [_selectedFrame, _setSelectedFrame] = useState('0');
  const {
    selectedNft,
    onValid
  } = props;

  const _materialOptions = [
    {id: '100', name: 'Gloss Ctype',},
    {id: '200', name: 'White Dibond',},
    {id: '300', name: 'Brushed Dibond',},
    {id: '400', name: 'Acrylic',},
  ];

  const _sizeOptions = [
    {id: '10', name: '12" x 12" (30cm X 30cm)',},
    {id: '20', name: '24" x 24" (60cm X 60cm)',},
    {id: '30', name: '48" x 48" (120cm X 120cm)',},
  ];

  const _frameOptions = [
    {id: '0', name: 'Unframed',},
    {id: '1', name: 'With subframe',},
    {id: '2', name: 'Tray Frame',},
    {id: '3', name: 'Glazed Box Frame',},
  ];

  const _handleSetSelectedSize = (value) => {
    _setSelectedSize(value);
  }

  const _handleSetSelectedFrame = (value) => {
    _setSelectedFrame(value);
  };

  const _clearSelections = () => {
    _setSelectedSize('20');
    _setSelectedFrame('0');
  };

  const _filterFrameOptions = (frame, key) => {
    if ((frame.id === '1' || frame.id === '2') && key === '100')
      return false;

    if (frame.id === '3' && key !== '100')
      return false;

    return true;
  };

  const _submit = (materialId) => {
    const material = _.find(_materialOptions, (m) => m.id === materialId);
    const size = _.find(_sizeOptions, (s) => s.id === _selectedSize);
    const frame = _.find(_frameOptions, (f) => f.id === _selectedFrame);

    onValid({
      material,
      size,
      frame,
      total: _calculateCost(materialId)
    });
  };

  const _calculateCost = (materialId) => {
    let total = parseInt(materialId) + parseInt(_selectedSize) + parseInt(_selectedFrame);

    switch (total) {
      case 110: return 1;
      case 113: return 1;
      case 120: return 1;
      case 123: return 1;
      case 130: return 1;
      case 133: return 1;

      case 210: return 1;
      case 211: return 1;
      case 212: return 1;
      case 220: return 1;
      case 221: return 1;
      case 222: return 1;
      case 230: return 1;
      case 231: return 1;
      case 232: return 1;

      case 310: return 1;
      case 311: return 1;
      case 312: return 1;
      case 320: return 1;
      case 321: return 1;
      case 322: return 1;
      case 330: return 1;
      case 331: return 1;
      case 332: return 1;

      case 410: return 1;
      case 411: return 1;
      case 412: return 1;
      case 420: return 1;
      case 421: return 1;
      case 422: return 1;
      case 430: return 1;
      case 431: return 1;
      case 432: return 1;

      default: return 0;
    }
  };

  const _render = (name, materialId, key) => {
    return (
      <Accordion.Item key={key} eventKey={materialId} className={styles.accordionItem
      }>
        <Accordion.Header className={styles.accordionHeader}>{name}</Accordion.Header>
        <Accordion.Body className={styles.accordionBody}>
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className={styles.section}>
                  <p className={styles.title}>Size</p>

                  {
                    _sizeOptions.map((size, i) =>
                      <div key={i}>
                        <Radio
                          className={styles.input}
                          value={size.id}
                          checked={_selectedSize === size.id}
                          onChange={_handleSetSelectedSize}
                          text={size.name}
                        />
                      </div>)
                  }

                </div>
              </Col>

              <Col xs={12} md={6}>
                <div className={styles.section}>
                  <p className={styles.title}>Frame</p>

                  {
                    _frameOptions.filter((value) => _filterFrameOptions(value, materialId)).map((frame, i) =>
                      <div key={i}>
                        <Radio
                          className={styles.input}
                          value={frame.id}
                          checked={_selectedFrame === frame.id}
                          onChange={_handleSetSelectedFrame}
                          text={frame.name}
                        />
                      </div>)
                  }
                </div>
              </Col>
            </Row>
          </Container>

          <div className={styles.buttonContainer}>
            <div className={styles.total}>Total:&nbsp;{_calculateCost(materialId)}&nbsp;ETH</div>
            <Button disabled={_selectedSize === ''} onClick={() => _submit(materialId)}>
              Next
            </Button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    );
  }

  return (
    <div>
      <p className={styles.pageTitle}>Choose Your Printing</p>

      <div className={styles.avatarContainer}>
        <div className={styles.nftContainer}>
          <div>
            <img src={selectedNft.image} width={'100%'} alt={'Loading...'} />
          </div>
          <div className={styles.nftName}>{selectedNft.name}</div>
        </div>
      </div>

      <div className={styles.cardContainer}>
        <Accordion className={styles.card} onSelect={_clearSelections}>
          {
            _materialOptions.map((material, i) =>
              _render(material.name, material.id, i))
          }
        </Accordion>
      </div>
    </div>

  )
};

export default SelectPrintingOptionsView;
