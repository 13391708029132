//ui lib
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
//lib
import classNames from 'classnames';
//styles
import styles from './confirmPrint.module.css';
//components
import Button from '../../components/button';

const ConfirmPrintView = (props) => {
  const {
    onValid,
    selectedNft,
    printOptions,
    deliveryAddress
  } = props;

  return (
    <div>
      <p className={styles.pageTitle}>Confirm Your Order</p>

      <div className={styles.avatarContainer}>
        <div className={styles.nftContainer}>
          <div>
            <img src={selectedNft.image} width={'100%'} alt={'Loading...'} />
          </div>
          <div className={styles.nftName}>{selectedNft.name}</div>
        </div>
      </div>

      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.header}>
            Summary
          </div>

          <Container>
            <Row className={styles.rowPadding}>
              <Col xs={12} md={6} className={styles.bottomPadding}>
                <p className={styles.subtitle}>Delivery Address</p>
                <p className={styles.addressText}>{deliveryAddress.firstName}&nbsp;{deliveryAddress.lastName}</p>
                <p className={styles.addressText}>{deliveryAddress.address1}</p>
                {
                  !!deliveryAddress.address2 &&
                  <p className={styles.addressText}>{deliveryAddress.address2}</p>
                }
                <p className={styles.addressText}>{deliveryAddress.city}</p>
                <p className={styles.addressText}>{deliveryAddress.postCode}</p>
                <p className={styles.addressText}>{deliveryAddress.country}</p>
              </Col>

              <Col xs={12} md={6}>
                <p className={styles.subtitle}>Order Details</p>

                <div>
                  <div className={styles.printOptions}>{printOptions.material.name}</div>
                </div>

                <div>
                  <div className={styles.printOptions}>{printOptions.size.name}</div>
                </div>

                {
                  !!printOptions.frame &&
                  <div>
                    <div className={styles.printOptions}>{printOptions.frame.name}</div>
                  </div>
                }
              </Col>
            </Row>
          </Container>

          <div className={styles.buttonContainer}>
            <div className={classNames(styles.total)}>Total:&nbsp;{printOptions.total}&nbsp;ETH</div>

            <Button onClick={onValid}>
              Next
            </Button>
          </div>

        </div>
      </div>
    </div>
  )
};

export default ConfirmPrintView;
