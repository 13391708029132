//ui lib
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//lib
import classNames from 'classnames';
//styles
import styles from './enterDeliveryAddress.module.css';
//components
import Button from '../../components/button';

const EnterDeliveryAddressView = (props) => {
  const {
    onValid
  } = props;


  return (
    <div>
      <p className={styles.pageTitle}>Your Address</p>

      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <Form onSubmit={(e) => {
            e.preventDefault();
            const model = {
              firstName: e.target.elements.firstName.value,
              lastName: e.target.elements.lastName.value,
              address1: e.target.elements.address1.value,
              address2: e.target.elements.address2.value,
              country: e.target.elements.country.value,
              city: e.target.elements.city.value,
              postCode: e.target.elements.postCode.value,
            };

            onValid(model);
          }}>
            <Container>
              <Row>
                <Col xs={12} sm={6} xl={5}>
                  <Form.Group className={classNames(styles.textInput, 'mb-3')} controlId={'address.firstName'}>
                    <Form.Label className={styles.controlLabel}>First Name</Form.Label>
                    <Form.Control className={styles.input} type={'text'} required name={'firstName'} />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} xl={5}>
                  <Form.Group className={classNames(styles.textInput, 'mb-3')} controlId={'address.lastName'}>
                    <Form.Label className={styles.controlLabel}>Last Name</Form.Label>
                    <Form.Control className={styles.input} type={'text'} required name={'lastName'} />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl={10}>
                  <Form.Group className={classNames(styles.textInput, 'mb-3')} controlId={'address.address1'}>
                    <Form.Label className={styles.controlLabel}>Address Line 1</Form.Label>
                    <Form.Control className={styles.input} type={'text'} required name={'address1'}/>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl={10}>
                  <Form.Group className={classNames(styles.textInput, 'mb-3')} controlId={'address.address2'}>
                    <Form.Label className={styles.controlLabel}>Address Line 2</Form.Label>
                    <Form.Control className={styles.input} type={'text'} name={'address2'}/>
                    <Form.Text className={classNames('text-muted', styles.controlLabel)}>
                      optional
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl={10}>
                  <Form.Group className={classNames(styles.textInput, 'mb-3')} controlId={'address.country'}>
                    <Form.Label className={styles.controlLabel}>Country</Form.Label>
                    <Form.Control className={styles.input} type={'text'} required name={'country'}/>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl={10}>
                  <Form.Group className={classNames(styles.textInput, 'mb-3')} controlId={'address.city'}>
                    <Form.Label className={styles.controlLabel}>City</Form.Label>
                    <Form.Control className={styles.input} type={'text'} required name={'city'}/>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className={classNames(styles.textInput, 'mb-3')} controlId={'address.postalCode'}>
                    <Form.Label className={styles.controlLabel}>ZIP/ postcode</Form.Label>
                    <Form.Control className={styles.input} type={'text'} required name={'postCode'}/>
                  </Form.Group>
                </Col>
                <Col xs={6} sm={7}/>
              </Row>
            </Container>

            <div className={styles.buttonContainer}>
              <Button type={'submit'}>
                Next
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>

  )
};

export default EnterDeliveryAddressView;
