//styles
import styles from './orderComplete.module.css';
//component
import Button from '../button';

const OrderCompleteView = (props) => {
  const {
    onValid
  } = props;

  return (
    <div className={styles.container}>
      <p className={styles.pageTitle}>Thank You</p>
      <p className={styles.subtitle}>Your order is complete, we will email you with your confirmation.</p>

      <div>
        <Button onClick={onValid} className={styles.button}>
          Order Again
        </Button>
      </div>
    </div>
  )
};

export default OrderCompleteView;
