//lib
import classNames from 'classnames';
//styles
import styles from './radio.module.css';

const radioView = (props) => {
  const {
    value,
    checked,
    onChange,
    className,
    text,
    lightMode
  } = props;

  return (
    <label className={classNames(styles.label, (lightMode ? styles.lightMode : ''))} onClick={() => onChange(value)}>
      <div className={classNames(styles.radio, (className ? className : ''))}>
        {
          checked &&
          <div className={classNames(styles.checked)} />
        }
      </div>
      {text}
    </label>
  )
}

export default radioView;
