//components
import View from './enterDeliveryAddressView';

const EnterDeliveryAddressContainer = (props) => {
  const {
    isCurrentIndex,
    onValid,
  } = props;

  if (!isCurrentIndex)
    return null;

  return (
    <View
      onValid={onValid}/>
  );
};

export default EnterDeliveryAddressContainer;
