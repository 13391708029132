import {useState} from 'react';
//components
import View from './printNftView';
//web3
import { useAccount } from 'wagmi';

const PrintNftContainer = () => {
  const [selectedNft, _setSelectedNft] = useState(null);
  const [printOptions, _setPrintOptions] = useState(null);
  const [deliveryAddress, _setDeliveryAddress] = useState(null);
  const [walletAddress, _setWalletAddress] = useState(null);
  const { isConnected, } = useAccount();

  const processViewData = (data, index) => {
    switch (index) {
      case 0:
        _setWalletAddress(data);
        break;
      case 1:
        _setSelectedNft(data);
        break;
      case 2:
        _setPrintOptions(data);
        break;
      case 3:
        _setDeliveryAddress(data);
        break;
      case 0:
      default:
        break;
    }
  };

  const clearViewData = () => {
    _setSelectedNft(null);
    _setPrintOptions(null);
    _setDeliveryAddress(null);
    _setWalletAddress(null);
  };

  return (
    <View
      selectedNft={selectedNft}
      printOptions={printOptions}
      deliveryAddress={deliveryAddress}
      walletAddress={walletAddress}
      processViewData={processViewData}
      clearViewData={clearViewData}
      isConnected={isConnected}/>
  );
}

export default PrintNftContainer;
