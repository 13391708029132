//ui lib
import Spinner from 'react-bootstrap/Spinner';
//styles
import styles from './processingPayment.module.css';

const ProcessingPaymentView = () => {
  return (
    <div>
      <p className={styles.pageTitle}>Payment</p>
      <div className={styles.spinnerContainer}>
        <Spinner color={'#307a85 '} className={styles.spinner} />
        <p className={styles.text}>Processing your payment</p>
      </div>
    </div>
  )
};

export default ProcessingPaymentView;
