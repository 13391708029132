//lib
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
//screens
import PrintNft from '../screens/printNft';

const router = createBrowserRouter([
  {
    path: '/',
    element: <PrintNft />
  },
]);

const Router = () => {
  return (
    <RouterProvider router={router} />
  )
};

export default Router;
