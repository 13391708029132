//ui lib
import Navbar from 'react-bootstrap/Navbar';
//assets
import Phygicool from '../../assets/Phygicool logo 300dpi.png';
//components
import Button from '../button';
//css
import styles from './header.module.css';

const headerView = ({showAddress, disconnectWallet}) => {
  return (
    <Navbar className={styles.navbar}>
      <Navbar.Brand>
        <div className={styles.imageContainer}>
          <a className={styles.image} href={'https://phygi.cool/'}>
            <img src={Phygicool} width={'150'} className={styles.image} />
          </a>
        </div>
      </Navbar.Brand>

      <Navbar.Collapse className='justify-content-end'>
        {
          showAddress &&
          <>
            <Button onClick={disconnectWallet}>
              Disconnect Wallet
            </Button>
          </>
        }
      </Navbar.Collapse>
    </Navbar>
  )

};

export default headerView;
