import {useEffect, useState} from 'react';
//components
import View from './headerView';
//web3
import { useAccount, useDisconnect } from 'wagmi';

const HeaderContainer = () => {
  const { isConnected, } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const [showAddress, setShowAddress] = useState(false);

  useEffect(() => {
    setShowAddress(isConnected);
  }, [isConnected]);

  const disconnectWallet = async () => {
    await disconnectAsync();
  };

  return (
    <View
      showAddress={showAddress}
      disconnectWallet={disconnectWallet}/>
  )
};

export default HeaderContainer;
