//ui lib
import Button from 'react-bootstrap/Button';
//lib
import classNames from 'classnames';
//styles
import styles from './button.module.css';

const buttonView = (props) => {
  const {
    onClick,
    disabled,
    children,
    fullWidth,
    type,
    lightButton,
    className
  } = props;

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      type={type ? type : 'button'}
      className={classNames(styles.button, (fullWidth ? styles.fullWidth : ''), (lightButton ? styles.buttonLight : ''), (className ? className : ''))}>
      {
        children
      }
    </Button>
  )
}

export default buttonView;
